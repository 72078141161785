<template>
  <div>
    <van-tabs v-model="active" color="rgb(58, 131, 244)" title-active-color="rgb(58, 131, 244)" @click="changeTab()">
      <van-tab title="社保信息">
        <div class="border"></div>
        <van-cell hover-class="none">
          <div class="form-head">
            <div class="form-blue-view"></div>
            <div class="form-title-view">社保缴纳信息</div>
          </div>
        </van-cell>
        <van-field
          :readonly="true"
          v-model="dataForm.socialNumber"
          name="社会保障号码"
          label="社会保障号码"
        />
        <van-field
          :readonly="true"
          v-model="dataForm.companyName"
          name="工作单位"
          label="工作单位"
        />
        <van-field
          :readonly="true"
          v-model="dataForm.jobStatus"
          name="就业状态"
          label="就业状态"
        />
        <van-field
          :readonly="true"
          v-model="dataForm.startDate"
          name="开始日期"
          label="开始日期"
        />
        <van-field
          :readonly="true"
          v-model="dataForm.checkDate"
          name="排查时间"
          label="排查时间"
        />
      </van-tab>
      <van-tab title="基本信息">
        <res-info ref="resInfo"></res-info>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import resInfo from './resInfo.vue'
export default {
  components: {
    resInfo
  },
  mounted () {
    this.options = this.$route.query
    this.id = this.options.id || ''
    this.getInfo()
  },
  data () {
    return {
      dataForm: {
        socialNumber: 1,
        companyName: '',
        jobStatus: '',
        startDate: '',
        checkDate: ''
      },
      options: null,
      init: true,
      active: 0,
      id: ''
    }
  },
  methods: {
    changeTab () {
      if (this.active == 1 && this.init) {
        this.init = false
        this.$nextTick(() => {
          this.$refs.resInfo.init(this.options)
          this.$refs.resInfo.control.disabled = true
        })

      }
    },
    getInfo () {
      this.$http({
        url: this.$http.adornUrl(`/wxapp/cntunemployment/info/${this.id}`),
        method: 'get',
        params: this.$http.adornParams()
      }).then(({data}) => {
        if (data && data.code === 0) {
          this.dataForm.socialNumber = data.appCntUnemployment.security.socialNumber
          this.dataForm.companyName = data.appCntUnemployment.security.companyName
          this.dataForm.jobStatus = data.appCntUnemployment.security.jobStatus == 1 ? '就业' : data.appCntUnemployment.security.jobStatus == 2 ? '失业' : '疑似失业'
          this.dataForm.startDate = data.appCntUnemployment.security.startDate
          this.dataForm.checkDate = data.appCntUnemployment.security.checkDate.split(' ')[0]
        } else {
          this.$message.error(data.msg)
        }
      })
    }
  }
}
</script>
<style scoped>
.border {
  width: 100%;
  height: 20px;
  background-color: #f5f5f5;
}
::v-deep .van-tab:first-child:after {
  content: '';
  position: absolute; 
  width: 3px;
  height: 50px;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  background-color: #f5f5f5; 
} 
</style>